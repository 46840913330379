<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" src="@/assets/gywm.png" alt="" />
      <div class="jsfnB1">关于然茜</div>
      <div class="jsfnC1">ABOUT RANCII</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">公司介绍</div>
        <div class="product">COMPANY INTRODUCTION</div>
      </div>
      <div class="bodyContent">
        <div class="leftContent">
            &emsp;&emsp;山东然茜信息科技有限公司成立于2018年1月，注册资金501万元，研发团队20余人。坚持“立足山东、面向全国、走向世界”的发展战略，积极探索实践以大数据算法为依托的服务模式，专注于行业智能软件、移动软件的研发、技术服务、技术咨询、服务与销售，具备完全独立知识产权。提供企业级智能方案，主要应用于政企、物联网、煤炭行业、交通物流及医疗等相关领域。公司与中国空间技术研究院、杭州排列科技、苏州极致医疗、山东消防总队等大型企业建立了良好合作伙伴关系。
        </div>
        <div><img style="width:100%;height:100%;" src="@/assets/gywmLOGO.png" alt=""></div>
      </div>
      <div>
        <div class="cpszh1">合作伙伴</div>
        <div class="product">PARTNERS</div>
      </div>
      <div class="bodyContentFont">
        &emsp;&emsp;然茜科技以物联网技术、人工智能、大数据、云计算作为技术支撑，从环保、工业、建筑等行业着手，集结产业链上不同特长的合作伙伴、企业，资源互通、产学互融、优势互补、共生共荣，锻造基于行业优势的强壮生态圈。
      </div>
      <div><img class="gywmh1" src="@/assets/gywmh1.png" alt=""></div>
      <div>
        <div class="cpszh1">加入我们</div>
        <div class="product">JOIN US</div>
      </div>
      <!-- <div class="jrwm">
        &emsp;&emsp;我们一直坚信，通过与志同道合的伙伴一起为之奋斗， 是充满前景的理想和事业，这里拥有宽广的发展平台和成长机遇，让我们一起成就公司成就自己。
      </div>
      <div>
        <div class="cpszh">招聘方式</div>
        <div class="product">RECRUITMENT</div>
      </div> -->
      <div class="bodyBottomContent">
        <div><img class="gywmj1" src="@/assets/gywmj1.png" alt=""></div>
        <div class="bodyBottomContentFont">
            <div class="bodyBottomContentFont1">招聘方式： </div>
            <div>&emsp;&emsp;以下职位申请请发邮件至rancii@126.com</div>
            <div>简历中请注明：</div>
            <div class="bodyBottomContentFont2">&emsp;&emsp;1.您所申请的职位</div>
            <div>&emsp;&emsp;2.您所期望的薪资要求</div>
            <div class="bodyBottomContentFont3">&emsp;&emsp;3.请在email中注明：应聘者姓名--应聘岗位--应聘部门</div>
            <div class="more">
                <div class="lj">&emsp;&emsp;了解更多岗位</div>
                <div class="lj">></div>
            </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  methods: {
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
div {
  box-sizing: content-box !important;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.cpszh1 {
  font-size: 24px;
  padding: 60px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.bodyContent {
  width: 1084px;
  /* height: 347px; */
  height: 270px;
  background: #f4f4f4;
  border-radius: 8px;
  margin: 24px 0 0 32px;
  padding: 24px 60px 43px 24px;
  display: flex;
}
.leftContent {
    width: 720px;
    height: 280px;
    margin-right: 52px;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 1px;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
.bodyContentFont {
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 1px;
    margin: 16px 20px 40px 32px;
}
.jrwm {
    margin: 16px 3px 20px 32px;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 1px;
}
.bodyBottomContent {
    display: flex;
}
.gywmj1 {
    margin: 16px 75px 0 32px;
}
.bodyBottomContentFont {
    margin: 16px 0 0 0;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 1px;
}
.bodyBottomContentFont2 {
    margin: 40px 0;
}
.bodyBottomContentFont3 {
    margin: 40px 0 50px 0;
}
.lj {
  color: #000;
  cursor: pointer;
}
</style>
